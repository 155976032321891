import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAccountsAction, addAccountAction, deleteAccountsAction, searchCustomerAction, updateAccountsAction, getAccountRolesAction } from '../../actions/accountActions';
import { getCustomersAction } from '../../actions/customerActions';
// import './Accounts.css';
import PageHeader from '../../components/PageHeader/PageHeader';
import CustomTable from '../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../utils/RolesAndAbilities';
import { showMultiDelete, showSuccess, showExtraConfirm } from '../../utils/Notifications';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Modal, Button, Dropdown, Menu, Input, Row, Col, message, Drawer, Select } from 'antd';
import { Link } from 'react-router-dom';
import AccountsSideMenu from './AccountsSideMenu';

function onChange(pagination, sorter){}

const { Option } = Select;

const confirm = Modal.confirm;


class Accounts extends Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    visible: false,
    drawer: false,
    loading: true,
    filters: [
      {
        text: 'Beheerder',
        // value: 'admin',
        value: 'Beheerder',
      },
      {
        text: 'Gebruiker',
        // value: 'user',
        value: 'Gebruiker',
      }
    ]
  };

  componentDidMount() {

    this.props.getAccountRolesAction().then(() => {

        var filters = this.state.filters;

        this.props.accountroles.map((accountrole) => {

            filters.push({
                text: accountrole.name,
                value: ''+accountrole.name
            });
        });

        this.setState({ filters: filters }, () => {
            this.props.getAccountsAction().then(() => {
                this.setState({ loading: false });
            });
        });
    });
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  }

  showConfirm() {
    showMultiDelete((multidelete) => {
      if(multidelete) {
        this.props.deleteAccountsAction({'ids': this.state.selectedRowKeys}).then(() => {
          this.setState({selectedRowKeys: []})
          showSuccess('Succesvol verwijderd')
        });
      }
    });
  }

  showConfirm2() {
    Modal.confirm({
      title: 'Wil je deze gebruikers deactiveren?',
      content: 'Als je op JA klikt, worden de gebruikers gedeactiveerd.',
      okText: 'Ja',
      okType: 'danger',
      cancelText: 'Nee',
      onOk: () => {
          this.props.updateAccountsAction({'ids': this.state.selectedRowKeys, 'enabled': false}).then(() => {
            this.setState({selectedRowKeys: []});
            showSuccess('Succesvol gedeactiveerd');
            this.props.getAccountsAction();
          });
      },
      onCancel() {
        // callback(false);
      },
    });
  }

  showConfirm3() {
      Modal.confirm({
        title: 'Wil je deze gebruikers activeren?',
        content: 'Als je op JA klikt, worden de gebruikers geactiveerd.',
        okText: 'Ja',
        okType: 'danger',
        cancelText: 'Nee',
        onOk: () => {
            this.props.updateAccountsAction({'ids': this.state.selectedRowKeys, 'enabled': true}).then(() => {
              this.setState({selectedRowKeys: []});
              showSuccess('Succesvol geactiveerd');
              this.props.getAccountsAction();
            });
        },
        onCancel() {
          // callback(false);
        },
      });
  }

  addUser() {

      // if(this.props.customer.license_nr_of_users > 0 && this.props.customer.nr_of_users >= this.props.customer.license_nr_of_users)
      // {
      //     showExtraConfirm((confirm) => {
      //         if(confirm)
      //         {
      //           this.props.history.push('/accounts/add');
      //         }
      //     }, 'Licentie', 'De limiet van het aantal gebruikers voor je licentie wordt hiermee overschreden. Extra kosten zullen dan in rekening worden gebracht. Weet je zeker dat je door wilt gaan?')
      // }
      // else
      // {
          this.props.history.push('/accounts/add');
      // }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideDefaultSelections: true,
      onSelection: this.onSelection,
      getCheckboxProps: record => ({
        disabled: this.props.auth && this.props.auth.user && record.id === this.props.auth.user.id ? true : false
      }),
    };

    const default_columns = [
    {
      title: 'E-mail',
      dataIndex: 'email',
      sorter: (a, b) => { return a.email.localeCompare(b.email)},
      render: (text, record) => (
        <span>
          <Link to={`/accounts/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },
    {
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.first_name.localeCompare(b.first_name)},
      render: (text, record) => (
        <span>
          <Link to={`/accounts/${record.id}/detail`}>
            {record.first_name + (record.affix ? ' ' + record.affix : '') + ' ' + record.last_name}
          </Link>
        </span>
      )
    },
    {
      title: 'Actief',
      dataIndex: 'enabled',
      sorter: (a, b) => { return a.enabled.localeCompare(b.enabled)},
      render: (text, record) => (
        <span>
          {text == '1' ? 'Ja' : 'Nee'}
        </span>
      )
    },
    {
      title: 'Rol',
      dataIndex: 'global_user_role',
      sorter: (a, b) => { return a.global_user_role.localeCompare(b.global_user_role)},
      // filters: this.state.filters,
      // filterMultiple: true,
      // onFilter: (value, record) => { return record.global_user_role === value },
    }];

    const columns = [{
      title: 'E-mail',
      dataIndex: 'email',
      sorter: (a, b) => { return a.email.localeCompare(b.email)},
      render: (text, record) => (
        <span>
          <Link to={`/accounts/${record.id}/detail`}>
            {text}
          </Link>
        </span>
      )
    },
    {
      title: 'Naam',
      dataIndex: 'name',
      sorter: (a, b) => { return a.first_name.localeCompare(b.first_name)},
      render: (text, record) => (
        <span>
          <Link to={`/accounts/${record.id}/detail`}>
            {record.first_name || record.last_name ? (record.first_name ? record.first_name : '') + (record.affix ? (record.first_name ? ' ' : '') + record.affix : '') + (record.first_name || record.affix ? ' ' : '') + record.last_name : ''}
          </Link>
        </span>
      )
    },
    {
      title: 'Extern',
      dataIndex: 'external',
      sorter: (a, b) => { return a.external.localeCompare(b.external)},
      render: (text, record) => (
        <span>
          {text == '1' ? 'Ja' : 'Nee'}
        </span>
      )
    },
    {
      title: 'Actief',
      dataIndex: 'enabled',
      sorter: (a, b) => { return a.enabled.localeCompare(b.enabled)},
      render: (text, record) => (
        <span>
          {text == '1' ? 'Ja' : 'Nee'}
        </span>
      )
    },
    {
      title: 'Rol',
      dataIndex: 'global_user_role',
      sorter: (a, b) => { return a.global_user_role.localeCompare(b.global_user_role)},
      render: (text, record) => (
          <span>{ text == 'Beheerder' ? 'Beheerder (oude rol, vervang en niet meer gebruiken)' : (text == 'Gebruiker' ? 'Gebruiker (oude rol, vervang en niet meer gebruiken)' : text) }</span>
      ),
      filters: this.state.filters,
      filterMultiple: true,
      onFilter: (value, record) => { return record.global_user_role === value },
    }];

    return (
      <div>
        <PageHeader path={[{'label': 'Accounts'}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
            <AccountsSideMenu activeMenu='1' />
            <div style={{padding: 24, width: '100%'}}>
                <Row className="mbm">
                  <Col span={12} >
                    <Button disabled={!this.props.HaveAbility('users' ,'write')} icon={<PlusOutlined />} type="primary ant-btn-new" onClick={() => { this.addUser(); } /*this.props.history.push('/accounts/add')*/}>
                      Toevoegen
                    </Button>
                    <Dropdown trigger={['click']} disabled={ this.props.HaveAbility('users', 'write') && (this.state.selectedRowKeys.length > 0) ? false : true } overlay={(
                        <Menu>
                          <Menu.Item onClick={() =>this.showConfirm2()} key="6" style={{ }}>Deactiveren</Menu.Item>
                          <Menu.Item onClick={() =>this.showConfirm3()} key="7" style={{ }}>Activeren</Menu.Item>
                          <Menu.Divider/>
                          <Menu.Item onClick={() => this.showConfirm()} key="5" disabled={this.props.HaveAbility('users', 'delete') ? this.state.selectedRowKeys.length > 1 : true } style={{ color: this.props.HaveAbility('users' ,'delete') ? (this.state.selectedRowKeys.length > 1 ? '' : '#f5222d') : '' }}>Verwijderen</Menu.Item>
                        </Menu>
                      )}>

                      <Button type="secondary">
                        Acties <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col span={12}>
                    <Input.Search
                      allowClear
                      placeholder="Zoeken..."
                      onSearch={ value => this.props.searchCustomerAction(value) }
                      style={{ width: 200, float: 'right' }}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <CustomTable
                      size="middle"
                      rowKey='id'
                      rowSelection={rowSelection}
                      onChange={onChange}
                      columns={this.state.loading ? default_columns : columns}
                      loading={this.state.loading}
                      dataSource={this.state.loading ? [] : this.props.accounts}
                      />
                  </Col>
                </Row>
            </div>
        </div>
      </div>
    );
  }
}
const AccountsAdd = Form.create({ name: 'add_account' })(Accounts);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.account.isFetching,
    isCustomerFetching : state.customer.isFetching,
    isError : state.account.isError,
    auth: state.auth.user,
    accounts : state.account.accounts,
    customers : state.customer.customers,
    // customer : state.customer.customer,
    customer : state.auth.selectedCustomer,
    accountroles : state.account.accountroles,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountsAction : bindActionCreators(getAccountsAction, dispatch),
    addAccountAction : bindActionCreators(addAccountAction, dispatch),
    deleteAccountsAction : bindActionCreators(deleteAccountsAction, dispatch),
    getCustomersAction : bindActionCreators(getCustomersAction, dispatch),
    searchCustomerAction : bindActionCreators(searchCustomerAction, dispatch),
    HaveAbility : bindActionCreators(HaveAbility, dispatch),
    updateAccountsAction : bindActionCreators(updateAccountsAction, dispatch),
    getAccountRolesAction : bindActionCreators(getAccountRolesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsAdd);
