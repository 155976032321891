import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerTransactions, exportCustomerTransactions } from '../../actions/transactionActions';
import { showSuccess } from '../../utils/Notifications';
import CustomTable from '../../components/CustomTable/CustomTable';
import AdministrationSideMenu from './AdministrationSideMenu';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, DatePicker, Collapse, Table } from 'antd';
import PageHeader from '../../components/PageHeader/PageHeader';
import moment from 'moment';
import { API_URL } from '../../constants/settings';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
dayjs.locale('nl');

const { Title } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Datum',
    width: 150,
    dataIndex: 'date',
    sorter: (a, b) => { return moment(a.date).unix() - moment(b.date).unix()},
    render: (text, index) => {
      return (
        <span>{moment(text, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span>
      )
    }
  },{
    title: 'Type',
    sorter: (a, b) => { return a.type.localeCompare(b.type)},
    dataIndex: 'type',
  },{
    title: 'Aantal',
    sorter: (a, b) => { return a.nr_of - b.nr_of},
    dataIndex: 'nr_of',
  },{
    title: 'Prijs',
    sorter: (a, b) => { return a.total_price.localeCompare(b.total_price)},
    width: 100,
    dataIndex: 'total_price',
    render: (text, index) => {
      return (
        <span>{text}</span>
      )
    }
  }
];

class CustomerTransaction extends Component {
  state = {
    edit: true,
    billing: [],
    selectedDate: [dayjs(moment().startOf('month')), dayjs(moment().endOf('month'))],
  };

  componentDidMount() {

      this.props.getCustomerTransactions(this.props.customer.id, moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')).then(() => {

          this.setState({ billing: this.props.billing });
      });
  }

  getTransactions = (from_date, untill_date) => {

    // this.setState({selectedDate: month});

    this.setState({ billing: [] });

    this.props.getCustomerTransactions(this.props.customer.id, from_date.format('YYYY-MM-DD'), untill_date.format('YYYY-MM-DD')).then(() => {

        this.setState({ billing: this.props.billing });
    });
  }

  downloadExport(from_date, untill_date)
  {
      window.open(API_URL + '/customer/' + this.props.customer.id +
        '/transactions/export?from_date=' + moment(from_date).format('YYYY-MM-DD') +
        '&untill_date=' + moment(untill_date).format('YYYY-MM-DD') +
        '&token=' + localStorage.getItem('authToken')
        ,
        '_blank');
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <PageHeader path={[{'label': 'Administratie'}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <AdministrationSideMenu activeMenu='1' />
          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={12} >
                <RangePicker
                    defaultValue={this.state.selectedDate}
                    onChange={(date, dateString) => {

                        // console.log(date, dateString);

                        this.setState({ selectedDate: date });

                        if(dateString[0] && dateString[1])
                        {
                            this.getTransactions(date[0], date[1]);
                        }
                    }}
                    style={{marginLeft: 8, marginBottom: 30}}
                    format={'DD-MM-YYYY'}
                    />
                <Button disabled={!this.state.selectedDate[0] || !this.state.selectedDate[1] ? true : false} onClick={() => { this.downloadExport(this.state.selectedDate[0], this.state.selectedDate[1]); }} style={{marginLeft: '8px'}}>Exporteren</Button>
              </Col>
            </Row>

            <Divider></Divider>

            <Row className="mbm">
              <Col span={12} >
                <span style={{fontWeight: 'bold'}}>Totale prijs: { this.state.billing.total_price }</span>
              </Col>
            </Row>
            <Collapse>
            {
                this.state.billing.projects && this.state.billing.projects.map((object, key) => (
                    <Panel header={'[nummer: '+object.project.project_number+'] '+object.project.name} extra={object.total_price} key={key}>
                        <Table
                          size="middle"
                          rowKey='id'
                          rowSelection={null}
                          columns={columns}
                          dataSource={object.transactions}
                          pagination={false}
                          />
                      </Panel>
                ))
            }
            </Collapse>

          </Form>
        </div>
      </div>
    );
    }
}

const CustomerTransactionForm = Form.create({ name: 'customer_modules' })(CustomerTransaction);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customer : state.auth.selectedCustomer,
    billing: state.transaction.billing,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerTransactions : bindActionCreators(getCustomerTransactions, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTransactionForm);
