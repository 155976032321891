import React, { Component } from 'react';
// import '../Customers.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getCustomerAction, syncCustomerModulesAction } from '../../../actions/customerActions';
import { showSuccess } from '../../../utils/Notifications';
import CustomerSideMenu from './CustomerSideMenu';
import { CheckOutlined, CloseOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Row, Col, Typography, Button, Switch, Divider, Input, Tooltip } from 'antd';
import PageHeader from '../../../components/PageHeader/PageHeader';
import AddFromMedia from '../../../components/AddFromMedia/AddFromMedia';
const { Title } = Typography;

class CustomerModules extends Component {
  state = {
    edit: true,
  };

  componentDidMount() {
    this.props.getCustomerAction(this.props.match.params.id).then(() => {
        this.setState({use_personal_login_page: this.props.customer.use_personal_login_page});
    });
  }

  toggle = () => {
    this.setState({
      edit: !this.state.edit
    });
  };

  handleSubmit = e => {
      e.preventDefault();

      this.props.form.validateFields((err, values) => {
        if (!err) {
          var result = Object.keys(values).filter((key) => {
            if(values[key] === true) {
              return key;
            }
            return null;
          });

          this.props.syncCustomerModulesAction(this.props.match.params.id, {'ids': result, 'license_nr_of_users': values.license_nr_of_users, 'license_nr_of_projects': values.license_nr_of_projects, 'license_nr_of_addresses': values.license_nr_of_addresses}).then(() => showSuccess());
        }
      });

  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <PageHeader path={[{'label': 'Klanten', 'link': '/customers'}, {'label': this.props.customer.name}]} />
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>
          <CustomerSideMenu id={this.props.match.params.id} activeMenu='3' />
          <Form onSubmit={this.handleSubmit} layout={'vertical'} style={{padding: 24, width: '100%'}} colon={true}>
            <Row className="mbm">
              <Col span={12} >
                <Button type="new" onClick={this.handleSubmit}>
                  Opslaan
                </Button>
                <Button onClick={() => this.props.history.push('/customers')}>Sluiten</Button>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={8}>
                <h2>Gebruikers</h2>
              </Col>
              <Col span={8}>
                <h2>Projecten</h2>
              </Col>
              <Col span={8}>
                <h2>Adressen</h2>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item style={{ float: 'left', display: 'inline-block' }} label={this.props.customer.nr_of_users + ' / '}>
                  {getFieldDecorator('license_nr_of_users', {
                    initialValue: this.props.customer.license_nr_of_users
                  })(
                    <Input style={{width: '100px', float: 'left', display: 'inline-block'}} />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={this.props.customer.nr_of_projects + ' / '}>
                  {getFieldDecorator('license_nr_of_projects', {
                    initialValue: this.props.customer.license_nr_of_projects
                  })(
                    <Input style={{width: '100px'}} />
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={this.props.customer.nr_of_addresses + ' / '}>
                  {getFieldDecorator('license_nr_of_addresses', {
                    initialValue: this.props.customer.license_nr_of_addresses
                  })(
                    <Input style={{width: '100px'}} />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Divider></Divider>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="Correspondentie">
                  {getFieldDecorator('5', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(5) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Berichten">
                  {getFieldDecorator('4', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(4) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Informatie">
                    {getFieldDecorator('12', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(12) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Werkvolgorde">
                  {getFieldDecorator('11', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(11) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Planning">
                  {getFieldDecorator('1', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(1) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Contactpersonen">
                    {getFieldDecorator('6', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(6) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Nieuws">
                  {getFieldDecorator('7', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(7) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Partners">
                  {getFieldDecorator('2', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(2) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Enquête">
                  {getFieldDecorator('9', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(9) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Afspraken">
                    {getFieldDecorator('3', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(3) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Documenten">
                  {getFieldDecorator('8', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(8) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="SMS">
                    {getFieldDecorator('10', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(10) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              {/*<Col span={12}>
                  <Form.Item label="Opname">
                    {getFieldDecorator('13', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(13) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        />
                    )}
                  </Form.Item>
              </Col>*/}
              <Col span={12}>
                  <Form.Item label="Draagvlak">
                    {getFieldDecorator('14', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(14) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Oplever">
                    {getFieldDecorator('15', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(15) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Keuze">
                    {getFieldDecorator('16', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(16) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              <Col span={12}>
                  <Form.Item label="Dossier">
                    {getFieldDecorator('17', {
                      valuePropName: 'checked',
                      initialValue: this.props.modules.indexOf(17) > -1
                    })(
                      <Switch
                        disabled={!this.state.edit}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        />
                    )}
                  </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Eigen module">
                  {getFieldDecorator('18', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(18) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Formulieren">
                  {getFieldDecorator('19', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(19) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={
                    <span>
                        Exporteren
                        <Tooltip class="mhs" title="Alleen van toepassing op de modules waar de gebruiker toegang tot heeft en welke een export mogelijkheid bieden.">
                            &nbsp;<InfoCircleTwoTone />
                        </Tooltip>
                    </span>
                }>
                  {getFieldDecorator('20', {
                    valuePropName: 'checked',
                    initialValue: this.props.modules.indexOf(20) > -1
                  })(
                    <Switch
                      disabled={!this.state.edit}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const CustomerModulesForm = Form.create({ name: 'customer_modules' })(CustomerModules);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching : state.customer.isFetching,
    isError : state.customer.isError,
    customer: state.customer.customer,
    modules: state.customer.modules,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCustomerAction : bindActionCreators(getCustomerAction, dispatch),
    syncCustomerModulesAction : bindActionCreators(syncCustomerModulesAction, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModulesForm);
