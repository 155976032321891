import React, { Component } from 'react'
import { FolderOutlined, HomeOutlined, TeamOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

export default class CustomerSideMenu extends Component {
    render() {
        return (
            <Menu
                mode="inline"
                style={{ width: 300}}
                defaultSelectedKeys={this.props.activeMenu}
                >
                <Menu.Item key={"1"}>
                    <Link to={!this.props.addressid ? false : `/projects/${this.props.projectid}/addresses/${this.props.addressid}/general`}>
                        <HomeOutlined />
                        <span>Algemeen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={"4"}>
                    <Link to={!this.props.addressid ? false : `/projects/${this.props.projectid}/addresses/${this.props.addressid}/fields`}>
                        <UnorderedListOutlined />
                        <span>Vrije velden</span>
                    </Link>
                </Menu.Item>
                <Menu.Item disabled={!this.props.addressid} key={"2"}>
                    <Link to={`/projects/${this.props.projectid}/addresses/${this.props.addressid}/contacts`}>
                        <TeamOutlined />
                        <span>Contactpersonen</span>
                    </Link>
                </Menu.Item>
                <Menu.Item disabled={!this.props.addressid || !this.props.HaveAbility('dossier', 'read')} key={"3"}>
                    <Link to={`/projects/${this.props.projectid}/addresses/${this.props.addressid}/dossier`}>
                        <FolderOutlined />
                        <span>Dossier</span>
                    </Link>
                </Menu.Item>
                <Menu.Item /*disabled={!this.props.addressid || !this.props.HaveAbility('dossier', 'read')}*/ key={"5"}>
                    <Link to={`/projects/${this.props.projectid}/addresses/${this.props.addressid}/bag-data`}>
                        <FolderOutlined />
                        <span>BAG data</span>
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }
}
