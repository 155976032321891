import React, { Component } from 'react';
// import './ProjectsAddressesDossier.css';
import ProjectsAddressesSideMenu from './ProjectsAddressesSideMenu';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { getAddressSharedDataAction, downloadDossier, requestDownloadDossier, resetAddressesDossierAction, deleteAddressDossierFilesAction, getAddressDocumentAction,  addDocumentToAddressDossierAction, updateDocumentForAddressDossierAction, addAddressNoteAction, getAddressNoteAction, patchAddressNoteAction, resetDossierAction } from '../../../../actions/addressesActions';
// import CustomTable from '../../../../components/CustomTable/CustomTable';
import { HaveRole, HaveAbility } from '../../../../utils/RolesAndAbilities';
// import { Link } from 'react-router-dom';
// import { showSuccess, showMultiDelete } from '../../../../utils/Notifications';
// import { CheckOutlined, CloseOutlined, DownOutlined, InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Dropdown,
  Menu,
  Switch,
  Button,
  Input,
  Modal,
  Drawer,
  Upload,
  Alert,
  Divider,
  Typography
} from 'antd';
import 'moment/locale/nl';

const { Title } = Typography;

class AdddressBagData extends Component {

    state = {};

    componentDidMount() {}

    render() {

      return (
        <div style={{backgroundColor: 'white', display: 'flex', border: '1px solid #e8e8e8', borderRadius: 5, overflow: 'hidden'}}>

          <ProjectsAddressesSideMenu projectid={this.props.match.params.id} addressid={this.props.match.params.addressid} activeMenu="4" {...this.props}/>

          <div style={{padding: 24, width: '100%'}}>

            <Row className="mbm">
              <Col span={24}>
                <Button type='new'>Bag data vernieuwen</Button>
              </Col>
            </Row>

            <Divider></Divider>

            <Title level={4}>{this.props.address.full_address}</Title>

            <Row className="mbm" gutter={24}>
                <Col className="gutter-row" span={4} style={{ fontWeight: 'bold' }}>Pand identificatienummer</Col>
                <Col className="gutter-row" span={20}>0123</Col>
            </Row>

            <Row className="mbm" gutter={24}>
                <Col className="gutter-row" span={4} style={{ fontWeight: 'bold' }}>Adres identificatienummer</Col>
                <Col className="gutter-row" span={20}>0123</Col>
            </Row>
            
            <Row className="mbm" gutter={24}>
                <Col className="gutter-row" span={4} style={{ fontWeight: 'bold' }}>Gebruiksdoel</Col>
                <Col className="gutter-row" span={20}>Kantoorfunctie</Col>
            </Row>
            
            <Row className="mbm" gutter={24}>
                <Col className="gutter-row" span={4} style={{ fontWeight: 'bold' }}>Oorspronkelijk bouwjaar</Col>
                <Col className="gutter-row" span={20}>2000</Col>
            </Row>
            
            <Row className="mbm" gutter={24}>
                <Col className="gutter-row" span={4} style={{ fontWeight: 'bold' }}>Status</Col>
                <Col className="gutter-row" span={20}>Naamgeving uitgegeven</Col>
            </Row>
            
            <Row className="mbm" gutter={24}>
                <Col className="gutter-row" span={4} style={{ fontWeight: 'bold' }}>Oppervlakte</Col>
                <Col className="gutter-row" span={20}>200m²</Col>
            </Row>

          </div>  

        </div>
      );
      }
}


const AdddressBagDataForm = Form.create({ name: 'projects_addresses_dossier' })(AdddressBagData);

const mapStateToProps = (state, ownProps) => {
  return {
    isFetching: state.address.isFetching,
    isError: state.address.isError,
    address: state.address.address,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    HaveAbility: bindActionCreators(HaveAbility, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdddressBagDataForm);
