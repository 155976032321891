import storage from 'redux-persist/lib/storage'

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import accountsReducer from './accountsReducer';
import customersReducer from './customersReducer';
import projectsReducer from './projectsReducer';
import addressesReducer from './addressesReducer';
import groupsReducer from './groupsReducer';
import chatsReducer from './chatsReducer';
import activitiesReducer from './activitiesReducer';
import occupantsReducer from './occupantsReducer';
import librariesReducer from './librariesReducer';
import workorderReducer from './workorderReducer';
import newsReducer from './newsReducer';
import informationReducer from './informationReducer';
import contactsReducer from './contactsReducer';
import documentsReducer from './documentsReducer';
import partnersReducer from './partnersReducer';
import surveysReducer from './surveysReducer';
import correspondencesReducer from './correspondencesReducer';
import appointmentsReducer from './appointmentsReducer';
import medialibraryReducer from './medialibraryReducer';
import scheduleReducer from './scheduleReducer';
import clientsReducer from './clientsReducer';
import letterPapersReducer from './letterPapersReducer';
import envelopesReducer from './envelopesReducer';
import dashboardReducer from './dashboardReducer';
import intakeReducer from './intakeReducer';
import supportBaseReducer from './supportBaseReducer';
import deliverReducer from './deliverReducer';
import choiceReducer from './choiceReducer';
import pushNotificationReducer from './pushNotificationReducer';
import complaintsReducer from './complaintsReducer';
import deliveryusersReducer from './deliveryusersReducer';
import formReducer from './formReducer';
import queueReducer from './queueReducer';
import transactionReducer from './transactionReducer';
import appointmentusersReducer from './appointmentusersReducer';
import licenseReducer from './licenseReducer';
import cobeeoptionReducer from './cobeeoptionReducer';


const appReducer = combineReducers({
    auth: authReducer,
    account: accountsReducer,
    customer: customersReducer,
    project: projectsReducer,
    address: addressesReducer,
    group: groupsReducer,
    chat: chatsReducer,
    activity: activitiesReducer,
    occupant: occupantsReducer,
    library: librariesReducer,
    workorder: workorderReducer,
    news: newsReducer,
    information: informationReducer,
    contact: contactsReducer,
    document: documentsReducer,
    partner: partnersReducer,
    survey: surveysReducer,
    correspondence: correspondencesReducer,
    appointment: appointmentsReducer,
    medialibrary: medialibraryReducer,
    schedule: scheduleReducer,
    clients: clientsReducer,
    letterpaper: letterPapersReducer,
    envelope: envelopesReducer,
    dashboard: dashboardReducer,
    intake: intakeReducer,
    support_base: supportBaseReducer,
    deliver: deliverReducer,
    choice: choiceReducer,
    pushnotification: pushNotificationReducer,
    complaints: complaintsReducer,
    delivery_users: deliveryusersReducer,
    forms: formReducer,
    queue: queueReducer,
    transaction: transactionReducer,
    appointment_users: appointmentusersReducer,
    license: licenseReducer,
    cobeeoption: cobeeoptionReducer,
});

const rootReducer = (state, action) => {

  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root')
    state = undefined
  }

  return appReducer(state, action)
}


export default rootReducer;
